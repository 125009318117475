
@media screen and ( max-width : 992px) {

    .u-width--100r {
        width: 100%;
    }
}

$width-sizes: ( 130, 160, 180, 200, 270, );
 @each $name in $width-sizes {
     %u-wth--#{$name},
     .u-wth--#{$name} {width: #{$name}px !important;}
 }

//specific widths
.u-width--100 {width: 100%;}