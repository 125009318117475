.list-group-item {
    .media-body {
        vertical-align: middle !important;
        span {
            color: rgba(52, 64, 78, 0.65);
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .media-left {
        vertical-align: middle;
        height: 50px;
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        img {
            max-width: 100%;
        }
    }
}