//  ------------------------------------
//   #Text colors
//  dependencies: $ui-colors map in 0_settings/_colors.scss
//  ------------------------------------
@each $color-name, $color in $ui-colors {
        %t-txt--#{$color-name}, .t-txt--#{$color-name} {
            color: $color;
        }
}

//  ------------------------------------
//   #Background colors
//  dependencies: $ui-colors map in 0_settings/_colors.scss
//  ------------------------------------
@each $color-name, $color in $ui-colors {
        %t-bgc--#{$color-name}, .t-bgc--#{$color-name} {
            background-color: $color;
        }
}

%u-bgc--transp, .u-bgc--transp {background-color: transparent !important;}