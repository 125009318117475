//  ------------------------------------
//  #Paddings
//  ------------------------------------


// paddings
%u-pdn--default, .u-pdn--default {padding: $base-spacing-unit !important;}
%u-pdn--1\/2, .u-pdn--1\/2       {padding: $base-spacing-unit/2 !important;}
%u-pdn--t\/2, .u-pdn--t\/2       {padding-top: $base-spacing-unit/2 !important;}
%u-pdn--r\/2, .u-pdn--r\/2       {padding-right: $base-spacing-unit/2 !important;}
%u-pdn--b\/2, .u-pdn--b\/2       {padding-bottom: $base-spacing-unit/2 !important;}
%u-pdn--l\/2, .u-pdn--l\/2       {padding-left: $base-spacing-unit/2 !important;}

// padding reset
%u-pdn--reset, .u-pdn--reset {
    > :first-child {padding-top: 0 !important;}
    > :last-child  {padding-bottom: 0 !important;}
}

//  padding increments
//  usage: u-pdn--x0, u-pdn--bx2..
@for $i from 0 through 10 {
    //  all
    %u-pdn--x#{$i},.u-pdn--x#{$i}   {padding: $base-spacing-unit*$i !important;}
    //  top
    %u-pdn--tx#{$i},.u-pdn--tx#{$i} {padding-top: $base-spacing-unit*$i !important;}
    //  right
    %u-pdn--rx#{$i},.u-pdn--rx#{$i} {padding-right: $base-spacing-unit*$i !important;}
    //  bottom
    %u-pdn--bx#{$i},.u-pdn--bx#{$i} {padding-bottom: $base-spacing-unit*$i !important;}
    //  left
    %u-pdn--lx#{$i},.u-pdn--lx#{$i} {padding-left: $base-spacing-unit*$i !important;}
}


//responsive paddings
@for $i from 0 through 10 {
    //  all
    %u-pdn--resx#{$i},.u-pdn--resx#{$i}   {
        padding: $base-spacing-unit*$i/2 !important;
        @include bp(tab) {
            padding: $base-spacing-unit*$i !important;
        }
    }
}

@for $i from 0 through 10 {
    //  top
    %u-pdn--restx#{$i},.u-pdn--restx#{$i}   {
        padding-top: $base-spacing-unit*$i/2 !important;
        @include bp(tab) {
            padding-top: $base-spacing-unit*$i !important;
        }
    }
}

@for $i from 0 through 10 {
    //  bottom
    %u-pdn--resbx#{$i},.u-pdn--resbx#{$i}   {
        padding-bottom: $base-spacing-unit*$i/2 !important;
        @include bp(tab) {
            padding-bottom: $base-spacing-unit*$i !important;
        }
    }
}
@for $i from 0 through 10 {
    //  right
    %u-pdn--resrx#{$i},.u-pdn--resrx#{$i}   {
        padding-right: $base-spacing-unit*$i/2 !important;
        @include bp(tab) {
            padding-right: $base-spacing-unit*$i !important;
        }
    }
}

// specific paddings
.u-pdn--1 { padding: 1px;}