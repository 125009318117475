.tt-contact-wrapper {
    position: relative;
    padding: 40px 10px;
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    .tt-contact-info {
        padding-left: 80px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        color: #898989;
        padding-top: 2px;   
    }
    .tt-contact-img {
        float: left;
        width: 50px;
        height: 50px;
        font-size: 30px;
        line-height: 48px;
        border: 1px solid #3f8bb1;
        text-align: center;
        color: #3f8bb1;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }
}

.tt-contact-form {
    .c-input-1 {
    margin-bottom: 20px;
    }
    .c-input-1.color-2 {
        color: #959595;
        border-color: #d6d6d6;
    }
    .c-input-1.size-3 {
        font-size: 15px;
        height: 55px;
        line-height: 53px;
    }
    .c-input-1 {
        width: 100%;
        height: 45px;
        font-size: 14px;
        line-height: 43px;
        font-weight: 400;
        color: #c4c4c4;
        letter-spacing: 0.3px;
        background: #fff;
        padding: 0 20px;
        border: 1px solid #eee;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
    }
    .c-area-1 {
        display: block;
        width: 100%;
        height: 173px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #c4c4c4;
        letter-spacing: 0.3px;
        border: none;
        padding: 15px 20px;
        border: 1px solid #eee;
        background: #fff;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        color: #959595;
        border-color: #d6d6d6;
    }
    .c-btn {
        border-color: #6d6d6d;
        color:white;
        margin-top: 30px;
    button {
        background: #3f8bb1;
        border: 0;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        padding: 10px;
    }
}
}
.contact-single { 
    min-height: 150px;
    
    }