%u-dsp--b , .u-dsp--b            {display: block !important;}
%u-dsp--i , .u-dsp--i            {display: inline !important;}
%u-dsp--ib, .u-dsp--ib           {display: inline-block !important; max-width: 100%;}
%u-dsp--t , .u-dsp--t            {display: table !important;}
%u-dsp--tc, .u-dsp--tc           {display: table-cell !important;}
%u-dsp--tr, .u-dsp--tr           {display: table-row !important;}
%u-dsp--f , .u-dsp--f            {display: flex !important;}
%u-dsp--n , .u-dsp--n           {display: none;}  // toggled

.a-item-center {
    align-items: center;
}