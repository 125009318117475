//    Breakpoints Mixin
//    THIS MIXIN WORKS ONLY WITH LATEST SASS BETA (at the moment: 06/10/13 01:37:34)
//    usage: @include bp(tab) {...}
//------------------------------------------------------------
@mixin bp($point) {
  // Small devices (tablets, 768px and up)
  @if $point == tab {
    @media only screen and (min-width: 768px)  { @content; }
  }
  // Medium devices (desktops, 992px and up)
  @if $point == lap {
    @media only screen and (min-width: 992px) { @content; }
  }
  // Large devices (large desktops, 1200px and up)
  @if $point == desk {
    @media only screen and (min-width: 1242px) { @content; }
  }
  // Larger devices (larger desktops, 1280px and up)
  @if $point == deskplus {
    @media only screen and (min-width: 1280px) { @content; }
  }
}


//    Media Block
//    <div class="mediablock">
//      <img class="mediablock__img" src="http://placekitten.com/200/300" alt="">
//      <div class="mediablock__body">
//        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
//      </div>
//    </div>
//z    usage: @include mediablock(left, 12px, '.mediablock__img', '.mediablock__body');
//------------------------------------------------------------
@mixin mediablock($position: left, $spacing:0, $media: '.o-mediablock__img', $block: '.o-mediablock__body') {
  overflow: hidden;
  _overflow: visible;
  zoom: 1;
  #{unquote($media)} {
    float: $position;
    @if $spacing > 0 {
      margin-#{opposite-position($position)}:$spacing;
    }
    img {display: block;}
  }
  #{unquote($block)} {
    overflow:hidden;
    _overflow: visible;
    zoom: 1;
  }
  &.media--right {
    #{unquote($media)} {
      @if $spacing > 0 {
        margin-left: $spacing;
        margin-right: 0;
      }

    }
  }
  &.responsive {
    @media only screen and (max-width: 768px) {
      #{unquote($media)},
      #{unquote($block)} {
        display: block;
        float: none;
      }
      #{unquote($media)} {
        margin-right: 0;
        margin-bottom: $spacing;
        margin-left: 0;
      }
    }
  }
}

// bg-img

@mixin background-cover($imgpath) {
  background: url(#{$imgpath}) no-repeat center center;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$imgpath}', sizingMethod='scale');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$imgpath}', sizingMethod='scale')";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// box-shadow //
//    usage:    @include box-shadow(5px, 5px, 70px, 34px, #eeedf1);
//============================================================

@mixin box-shadow($shadow ...) {

      -webkit-box-shadow:$shadow;
      -moz-box-shadow:$shadow;
      box-shadow:$shadow;
}

//    border-radius that works on android devices
//    usage: @include b-radius(2px);
//============================================================

@mixin b-radius($radius) {
  border-radius: $radius;
}

//============================================================
//
// arrow
//
// @param width           :  px, em
// @param height          :  px, em
// @param direction       :  up, down, left, right
// @param color           :  hex, rgb
//
// usage: @include arrow(60px, 30px, down, #e88565);
//
//============================================================

@mixin arrow($width: 20px, $height: 20px, $direction: up, $color: red) {
  width: 0;
  height: 0;
  @if $direction == right {
    border-top: $height/2 inset transparent;
    border-bottom: $height/2 inset transparent;
    border-left: $width solid $color;
  } @else if $direction == left {
    border-top: $height/2 inset transparent;
    border-bottom: $height/2 inset transparent;
    border-right: $width solid $color;
  } @else if $direction == up {
    border-left: $width/2 inset transparent;
    border-right: $width/2 inset transparent;
    border-bottom: $height solid $color;
  } @else if $direction == down {
    border-left: $width/2 inset transparent;
    border-right: $width/2 inset transparent;
    border-top: $height solid $color;
  }
}