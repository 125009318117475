///  ------------------------------------
//  #Text utilities
//  ------------------------------------
%u-txt--center, .u-txt--center      {text-align: center !important;}
%u-txt--left, .u-txt--left          {text-align: left !important;}
%u-txt--right, .u-txt--right        {text-align: right !important;}
///  ------------------------------------
//  #Text responsive align
//  ------------------------------------

.u-txt--r-right {
    text-align: left;
    @include bp(tab) {text-align: right;}
}

//  text transformations
%u-txt--up, .u-txt--up              {text-transform: uppercase !important;}
%u-txt--low, .u-txt--low            {text-transform: lowercase !important;}
%u-txt--notr, .u-txt--notr          {text-transform: none !important;}
%u-txt--ita, .u-txt--ita            {font-style: italic !important;}

//  text line heights
%u-txt--lh1, .u-txt--lh1            {line-height: 1 !important;}
%u-txt--lh15, .u-txt--lh15         {line-height: 1.5 !important;}
%u-txt--lh12, .u-txt--lh12       {line-height: 1.2 !important;}
%u-txt--lh13, .u-txt--lh13       {line-height: 1.3 !important;}
%u-txt--lh18, .u-txt--lh18       {line-height: 1.8 !important;}

//  text sizes
//  dependencies: $text-sizes, maps in 0_settings/_vars.scss
@each $name, $size in $text-sizes {
    %u-txt--#{$name},
    .u-txt--#{$name}                {font-size: $size !important;}
}

//  text font weights
%u-txt--thin, .u-txt--light         {font-weight: 300 !important;}
%u-txt--normal, .u-txt--normal      {font-weight: 400 !important;}
%u-txt--thick, .u-txt--thick        {font-weight: 500 !important;}
%u-txt--semibold, .u-txt--semibold        {font-weight: 600 !important;}
%u-txt--bold, .u-txt--bold          {font-weight: 700 !important;}

a { color:#34404e!important;}