
 .flexslider {
     height: 491px;
     .flex-control-paging { display: none;}
     .flex-next {display: none;}
     .flex-prev {display: none;}
     margin-top: 9px;
     ul {
          height: 491px;
     li {
         height: 491px;
         background-size: cover;
     }
         .img1 { background-image: url('../../img/slider/slider1.jpg');}
         .img2 { background-image: url('../../img/slider/slider2.jpg');}
         .img3 { background-image: url('../../img/slider/slider3.jpg');}
         .img4 { background-image: url('../../img/slider/slider4.jpg');}
     }
 }
 .thumb-slder {
     height: 246px;
     padding: 10px;
     &--1 { background-color: #2db3c4;}
     &--2 { background-color: #318ad8;}
     &--3 { background-color: rgba(68, 67, 70, 0.63)}
     &--4 { background-color: #ea666a;}
     p {
        color: white;
         a{ color: white !important;}
     }
     .title{ 
             font-weight: bold;
             font-size: 18px;
        }
 }
a {text-decoration: none !important}