.product {
    height: 50px;
    display: flex;
    align-items: center;
    background: #34404e;
    padding: 10px;
    margin-top: 10px;
    a {
        color: rgba(255, 255, 255, 0.61) !important;
        font-size: 16px;
        height: 100%;
        width: 100%;
    }
}