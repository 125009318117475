.h--100-perc {
    height: 100%;
}

.h--auto {
    height: auto;
}

.h--100-min {
    min-height: 100%;
}

.h--100-r {
    height: initial;
    @include bp(lap) {
        height: 100%;
    }
}

$height-sizes: ( 60, 80);
@each $name in $height-sizes {
    %u-hgt--#{$name},
    .u-hgt--#{$name} {
        height: #{$name}px !important;
    }
}

// min-height
$height-sizes: ( 120, );
@each $name in $height-sizes {
    %u-hgt-min--#{$name},
    .u-hgt-min--#{$name} {
        min-height: #{$name}px !important;
    }
} //max-height
$height-sizes: ( 40);
@each $name in $height-sizes {
    %u-hgt-max--#{$name},
    .u-hgt-max--#{$name} {
        max-height: #{$name}px !important;
    }
}
//.c-header {
//    background: white;
//    font-family: 'Open Sans', sans-serif;
//    .icon-box-wrapper {
//        text-align: right;
//        .icon-box {
//            text-align: left;
//            padding-right: 30px;
//            margin: 10px 30px 10px 0;
//            @include bp(tab) {
//                border-right: 1px solid #eeeeee;
//                display: inline-block;
//            }
//        }
//    }
//}
