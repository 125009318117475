//  ------------------------------------
//  #Colors
//  ------------------------------------

//  Brand color


$white             :#ffffff;







//  ------------------------------------
//  #UI colors
//  ------------------------------------

$ui-colors: (
    'white'            : $white,

 );