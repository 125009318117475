.c-header {
	background: white;
	font-family: 'Open Sans', sans-serif;
	.icon-box-wrapper {
		text-align: right;
		.icon-box {
			text-align: left;
			padding-right: 30px;
			margin: 10px 30px 10px 0;
			 @include bp(tab) {
			 	border-right: 1px solid #eeeeee;
			 	display: inline-block;
			 }
		}
	}
	.c-mainnav {
		background-image: linear-gradient(to bottom, #383838 0%, #2f2f2f 100%);
		margin-bottom: 0px;
		border: none !important;
		border-radius: 0px;
		.navbar-nav {
			li {
				a {
					text-transform: uppercase;
					color: #bebebe !important;
					font-weight: 300;
					line-height: 25px;
				}
			}
		}
	}
}