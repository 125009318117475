html {
    height: 100%!important;
}

body {
    height: 100% !important;
    p {
        color: #34404e;
    }
    h2 {
        color: #34404e;
    }
}

.box-title {
    padding: 13px 20px;
    margin: -20px 0px 0px;
    background: #f9f9f9;
    display: block;
    border: 1px solid #eeeeee;
}

footer {
    overflow: hidden;
    background: #dad8d8;
    box-shadow: rgba(0, 0, 0, .05) 0 2px 2px, rgba(0, 0, 0, .05) 0 4px 4px;
    position: relative;
    height: 60px;
}

#push {
    height: 60px;
    margin-top: 20px;
}

#app {
    margin-bottom: -60px;
    position: relative;
    height: auto;
    overflow: hidden;
    min-height: 100%;
}

.wrap-title {
    background-image: url('../img/neotex.jpg');
    background-position: center center;
    position: relative;
    height: 220px;
    margin-bottom: 50px;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(49, 138, 216, 0.58);
        opacity: 0.9;
    }
    .page-title {
        position: relative;
        z-index: 100;
        color: rgba(158, 158, 158, 0.97);
        display: flex;
        align-items: center;
        padding-left: 10px;
        letter-spacing: 2px;
        h1 {
            font-weight: bold;
            background: rgba(0, 0, 0, 0.57);
            padding: 10px;
        }
    }
}