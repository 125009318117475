//  ------------------------------------
//  #Margins
//  ------------------------------------

//  margin reset
@for $i from 0 through 10 {
    // top
    %u-mrg--restx#{$i},.u-mrg--restx#{$i}   {
        margin-top: $base-spacing-unit*$i/2 !important;
        @include bp(tab) {
            margin-top: $base-spacing-unit*$i !important;
        }
    }
}

@for $i from 0 through 10 {
    // bottom
    %u-mrg--resbx#{$i},.u-mrg--resbx#{$i}   {
        margin-bottom: $base-spacing-unit*$i/2 !important;
        @include bp(tab) {
            margin-bottom: $base-spacing-unit*$i !important;
        }
    }
}
//  margin reset
%u-mrg--reset, .u-mrg--reset {
    > :first-child {margin-top: 0 !important;}
    > :last-child  {margin-bottom: 0 !important;}
}
//  default margin (bottom only)
%u-mrg--default, .u-mrg--default {margin: 0 0 $base-spacing-unit !important;}
//  Other margins
%u-mrg--1\/2, .u-mrg--1\/2       {margin: $base-spacing-unit/2 !important;}
%u-mrg--t\/2, .u-mrg--t\/2       {margin-top: $base-spacing-unit/2 !important;}
%u-mrg--r\/2, .u-mrg--r\/2       {margin-right: $base-spacing-unit/2 !important;}
%u-mrg--b\/2, .u-mrg--b\/2       {margin-bottom: $base-spacing-unit/2 !important;}
%u-mrg--b\/4, .u-mrg--b\/4       {margin-bottom: $base-spacing-unit/4 !important;}
%u-mrg--l\/2, .u-mrg--l\/2       {margin-left: $base-spacing-unit/2 !important;}

//  margin increments
//  usage: u-mrg--x0, u-mrg--bx2...
@for $i from 0 through 10 {
    //  all
    %u-mrg--x#{$i},.u-mrg--x#{$i}   {margin: $base-spacing-unit*$i !important;}
    //  top
    %u-mrg--tx#{$i},.u-mrg--tx#{$i} {margin-top: $base-spacing-unit*$i !important;}
    //  right
    %u-mrg--rx#{$i},.u-mrg--rx#{$i} {margin-right: $base-spacing-unit*$i !important;}
    //  bottom
    %u-mrg--bx#{$i},.u-mrg--bx#{$i} {margin-bottom: $base-spacing-unit*$i !important;}
    //  left
    %u-mrg--lx#{$i},.u-mrg--lx#{$i} {margin-left: $base-spacing-unit*$i !important;}
}