$base-spacing-unit    : 5px;

$font2            :  'montserrat';

$text-sizes: (
  "6"     : 6px,
  "8"     : 8px,
  "12"    : 12px,
  "16"    : 16px,
  "18"    : 18px,
  "20"    : 20px,
  "22"    : 22px,
  "24"    : 24px,
  "28"    : 28px,
  "30"    : 30px,
  "34"    : 34px,
  "50"    : 50px,

);

$z-indexes: (
    '1000'   : 1000,
    '100'   : 100,
    '50'    : 50,
    '10'    : 10,
    '1'     : 1,
    '0'     : 0,
    '-1'    : -1,
);